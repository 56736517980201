/* Interview Dashboard */
body #page-Interview\ Feedback [data-fieldname=custom_question_assessment] .grid-body .row,
body .dialog-interview-feedback-above-50 .grid-body .row {
  height: -moz-fit-content;
  height: fit-content;
}
body #page-Interview\ Feedback [data-fieldname=custom_question_assessment] .grid-body .row div,
body .dialog-interview-feedback-above-50 .grid-body .row div {
  height: auto;
  max-height: none !important;
}
body #page-Interview\ Feedback [data-fieldname=custom_question_assessment] .grid-body .col-xs-3 .ellipsis,
body .dialog-interview-feedback-above-50 .grid-body .col-xs-3 .ellipsis {
  white-space: pre-wrap;
}
body .html-toggle {
  margin: 1rem 0;
}
body .rt-interview-summary-div {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
}
body .dialog-interview-feedback .grid-body .grid-static-col {
  height: auto !important;
  max-height: -moz-fit-content;
  max-height: fit-content;
}
body .dialog-interview-feedback .grid-body .grid-delete-row {
  display: none !important;
}
body .dialog-interview-feedback .grid-body .dialog-interview-feedback-skill {
  padding-right: 1rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid var(--table-border-color);
  font-size: 0.9rem;
  font-weight: bold;
  border-top: 2px solid var(--table-border-color);
}
body .dialog-interview-feedback .grid-body [data-fieldname~=skill] {
  display: none;
}
body .dialog-interview-feedback .grid-body [data-fieldname=question] .field-area,
body .dialog-interview-feedback .grid-body [data-fieldname=hints] .field-area {
  display: none !important;
}
body .dialog-interview-feedback .grid-body [data-fieldname=question] .static-area,
body .dialog-interview-feedback .grid-body [data-fieldname=hints] .static-area {
  display: block !important;
}
body .dialog-interview-feedback .grid-body [data-fieldname=question],
body .dialog-interview-feedback .grid-body [data-fieldname=hints] {
  padding: 8px !important;
  padding-right: var(--padding-sm) !important;
  padding-left: var(--padding-sm) !important;
}
body .dialog-interview-feedback .grid-body [data-fieldname=question] .ellipsis,
body .dialog-interview-feedback .grid-body [data-fieldname=hints] .ellipsis {
  white-space: break-spaces;
}
body .dialog-interview-feedback .grid-body [data-fieldname=hints] {
  flex: 25;
  max-width: 43.667%;
}
body .dialog-interview-feedback .grid-body [data-fieldname=question] {
  flex: 25;
  max-width: 24% !important;
}
body .dialog-interview-feedback .grid-body .grid-delete-row {
  display: none;
}
body .dialog-interview-feedback .grid-add-row,
body .dialog-interview-feedback .edit-grid-row {
  display: none;
}
body .dialog-interview-feedback .row-check,
body .dialog-interview-feedback .grid-heading-row {
  display: none;
}
body .dialog-interview-feedback .form-grid {
  margin: 1rem 0;
}
body .dialog-interview-feedback .col-3,
body .dialog-interview-feedback .col-xs-3 {
  max-width: 70%;
}
body .careers-kanban-list-pagin-area {
  margin-right: 1rem;
  position: absolute;
  display: flex;
  justify-content: space-between;
  right: 0;
  left: 0;
  padding: 0.5rem 3rem;
}